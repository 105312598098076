.lbh-link-group {
  margin-top: 4em;
  border-bottom: 1px solid #e5e5e5;

  &.lbh-link-group--spaced {
    margin-bottom: 4em;
  }

  > ul {
    display: flex;
    list-style: none;
    margin-bottom: -1px;
  }

  .lbh-link-group__item {
    margin: 0 1.5em 0 0;

    .lbh-link {
      padding: 0.25em 0;
      text-decoration: none;

      &.active {
        color: #0b0c0c;
        border-bottom: 5px solid #025ea6;
      }

      &:focus {
        box-shadow: none;
        border-bottom: 5px solid #025ea6;
      }
    }
  }
}

.lbh-link-group-vertical {
  > ul {
    display: flex;
    flex-direction: column;
    list-style: none;
  }

  .lbh-link-group__item {
    margin: 0;

    .lbh-link {
      border-left: 5px solid #ffffff;
      white-space: nowrap;
      padding: 0;
      text-decoration: none;
      line-height: 50px;
      padding: 10px;

      &.active {
        color: #0b0c0c;
        border-left: 5px solid #025ea6;
      }

      &:focus,
      &:hover {
        box-shadow: none;
        border-left: 5px solid #025ea6;
      }
    }
  }
}
