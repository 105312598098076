@import 'node_modules/lbh-frontend/lbh/components/lbh-summary-list/summary-list';

.lbh-summary-list {
  margin-top: 1.5em;
}

.lbh-summary-list--spaced {
  margin-top: 2em;

  .govuk-summary-list__key {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  @media (min-width: 40.0625em) {
    .govuk-summary-list__key {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
}

.govuk-summary-list__row {
  margin-top: 15px;
}

@each $position in bottom middle {
  .govuk-summary-list__row__#{$position} > * {
    vertical-align: $position;
  }
}

.lbh-summary-section {
  margin-bottom: 3em;
}

.lbh-summary-title {
  @media (max-width: 40.0625em) {
    h3 {
      margin-bottom: 15px;
    }
  }

  @media (min-width: 40.0625em) {
    display: flex;
    justify-content: space-between;

    .lbh-link {
      margin: 0;
    }
  }
}

.lbh-summary-title + .lbh-summary-list {
  margin-top: 1.5em;
}

.lbh-summary-answer {
  padding-top: 10px;
  padding-right: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #b1b4b6;
}

.govuk-summary-list__actions--wide {
  width: 60%;
  text-align: left;
}

@media (min-width: 40.0625em) {
  .govuk-summary-list--no-border .govuk-summary-list__actions,
  .govuk-summary-list--no-border .govuk-summary-list__key,
  .govuk-summary-list--no-border .govuk-summary-list__value,
  .govuk-summary-list__row--no-border .govuk-summary-list__actions,
  .govuk-summary-list__row--no-border .govuk-summary-list__key,
  .govuk-summary-list__row--no-border .govuk-summary-list__value {
    padding-bottom: 11px;
    border: 0;
  }
}
